<template>
    <div class="live-stream">
        <h2 class="header-title luxury">{{ activeProject.title }} {{ $fn.tr('Live') }}</h2>
        <div class="header">
            <div class="left">
                <div v-for="(item,index) in cameras"
                     @click="activeCameraIndex=index"
                     :class="{active:index===activeCameraIndex}"
                     class="item fira">{{ item.tipText }}
                </div>
            </div>
            <div class="right">
                <div class="dot"></div>
                <span class="title fira">{{ $fn.tr('Time in Georgia') }} :{{ time }}</span>
            </div>
        </div>
        <!--        <div v-html="live"></div>-->
        <div @click="showLive=true" class="video-opener" @mouseenter="store.getComponentCursor($event, 'play')"
             data-scale-type="1.2" @mouseleave="store.getComponentCursor($event, null)">
            <div class="play-icon">
                <svg width="33" height="37" viewBox="0 0 33 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.6211 19.4266L2.78027 35.5005C2.11361 35.8854 1.28028 35.4043 1.28028 34.6345L1.28028 2.48664C1.28028 1.71684 2.11361 1.23571 2.78028 1.62062L30.6211 17.6945C31.2878 18.0794 31.2878 19.0417 30.6211 19.4266Z"
                        stroke="white" stroke-width="2"/>
                </svg>
            </div>

            <picture class="image">
                <source
                    media="(max-width:767px)"
                    :srcset="image.mobile"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="image.tablet"
                />
                <img
                    :src="image.desktop"
                />
            </picture>
        </div>
        <teleport to="body">
            <transition name="fade">
                <div v-if="showLive" class="live-video">
                    <iframe id="iframe"
                            ref="video"
                            title="Inline Frame Example"
                            :src="activeCamera.tipHover">
                    </iframe>
                    <div @click="closeLive" class="icon-container">
                        <svg width="42" height="42"
                             class="icon"
                             viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.20312 1.70093L40.094 40.5918" stroke="white" stroke-width="2"
                                  stroke-linecap="round"></path>
                            <path d="M1.2041 40.592L40.095 1.70117" stroke="white" stroke-width="2"
                                  stroke-linecap="round"></path>
                        </svg>
                    </div>
                </div>
            </transition>
        </teleport>

    </div>
</template>

<script setup>
import {onMounted, ref, computed, nextTick} from "vue";
import {useMenuStore} from "../../../store/pinia/menu";

const props = defineProps({
    activeProject: {
        type: Object,
    },
});
let store = useMenuStore()
let video = ref(null)
let showLive = ref(false)
let cameras = computed(() => props.activeProject?.live_cameras?.[store.getLang] || [])
let image = computed(() => {
    let item = props.activeProject?.camera_image?.[0]?.devices
    return {
        mobile: item?.mobile,
        tablet: item?.tablet,
        desktop: item?.desktop,
    }
})
let activeCameraIndex = ref(0)
let time = ref(null)
let activeCamera = computed(() => cameras.value[activeCameraIndex.value] || {})
const setTime = () => {
    let d = new Date();
    let s = d.getSeconds();
    let m = d.getMinutes();
    let h = d.getHours();
    time.value =
        ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2);
}
const closeLive = () => {
    showLive.value = false
}
onMounted(async () => {
    await nextTick()

    setInterval(() => {
        setTime()
    }, 1000)
})
</script>


<style lang="scss" scoped>
.live-stream {
    color: $primaryNavy;

    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        text-transform: uppercase;
        @media (max-width: 1024px) {
            font-size: 28px;
            margin-bottom: 15px;
        }
    }

    .header {
        padding: 0 29px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $primaryNavy;
        color: white;

    }

    .left {
        display: flex;
        align-items: center;
        height: 100%;

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 21px;
            height: 100%;
            cursor: pointer;
            opacity: .5;
            transition: all .45s ease-out;
            box-sizing: content-box;
            position: relative;

            &:after {
                width: 100%;
                content: '';
                position: absolute;
                border-bottom: 1px solid transparent;
                transition: all .45s ease-out;
                bottom: -10px;
            }

            &.active {
                &:after {
                    opacity: 1;
                    border-bottom: 1px solid $burgundy;
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 767px) {
            display: none;
        }

        .dot {
            width: 8.82px;
            height: 8.82px;
            border-radius: 50%;
            background: #F40146;
            margin-right: 8px;
        }

        .title {
            font-weight: 400;
            font-size: 16px;
            text-align: right;
            text-transform: uppercase;

        }
    }

    .video-opener {
        margin-top: 42px;
        width: 100%;
        height: 678px;
        background: black;
        position: relative;
        @media only screen and (max-width: 768px) {
            height: 450px;
        }
        @media only screen and (max-width: 767px) {
            height: 350px;
        }

        .play-icon {
            border: 1px solid white;
            height: 100px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: rgba(0,0,0,.2);
        }

        .image {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            width: 60px;
            fill: white;
        }
    }

}

.live-video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: rgba(0, 0, 0, .5);

    iframe {
        width: 100%;
        height: 100%;
    }

    .icon-container {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(24, 34, 54, 0.3);
        border: 1px solid #FFFFFF;
        z-index: 99;

        .icon {
            width: 30px;
        }
    }

}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scale(.9);
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .live-stream {
            color: white;
        }
    }
}

</style>
